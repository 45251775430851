import oneBox from "@drift/oneplatfront";
import packageInfo from "../package.json";

const proxyBaseUrl = process.env.REACT_APP_PROXY_BASE_URL || "/proxy";

const appId = process.env.REACT_APP_ID || "demo";


oneBox
    .run({
        settings: {
            one: {
                appId,
                axios: {
                    proxy: {
                        baseUrl: proxyBaseUrl,
                    },
                },
                box: {
                    module: {
                        name: "PORTAL",
                        version: packageInfo.version,
                    },
                    powerboard: {
                        show: true,
                    },
                },
                layout: { title: "PORTAL" },
                gainsight: {
                    enabled: true,
                },
            },
        },
        features: [],
        services: [],
    })
    .catch(console.error);
